import { Component, OnInit } from '@angular/core';
import { SessionService } from 'app/services/session/session.service';

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

declare interface RouteName {
  path: string;
  title: string;
  showBackHistory: boolean;
}

//NOTE: Important! Path should be sorted with the longest path first.
export const ROUTE_NAMES: RouteName[] = [
  { path: '/gateway/details', title: 'Locator information', showBackHistory: true },
  { path: '/device/details', title: 'SmartTag information', showBackHistory: false },
  { path: '/device-general/details-general', title: 'Device information', showBackHistory: false },
  { path: '/dashboard', title: 'Dashboard', showBackHistory: false },
  { path: '/gateway', title: 'Locators', showBackHistory: false },
  { path: '/device', title: 'SmartTags', showBackHistory: false },
  { path: '/device-general', title: 'Devices', showBackHistory: false },
  { path: '/settings', title: 'Settings', showBackHistory: false },
];

export const ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'Dashboard', icon: 'dashboard', class: '' },
  { path: '/gateway', title: 'Locators', icon: 'router', class: '' },
  { path: '/device', title: 'SmartTags', icon: 'memory', class: '' },
  { path: '/device-general', title: 'Devices', icon: 'memory', class: '' },
  { path: '/settings', title: 'Settings', icon: 'settings', class: '' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor(public session: SessionService) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  };
}
