import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import * as L from 'leaflet';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { SessionService } from 'app/services/session/session.service';
import { GatewayCommandDTO, GatewayDTO } from 'app/lib/locator-api';
import { MatDialog } from '@angular/material/dialog';
import { DialogInformationComponent } from 'app/dialogs/dialog-information/dialog-information.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Observable, Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-gateway-details',
  templateUrl: './gateway-details.component.html',
  styleUrls: ['./gateway-details.component.scss']
})
export class GatewayDetailsComponent implements AfterViewInit {

  gatewayId: number;
  gateway: GatewayDTO = {};
  gatewayDiscovereLastHours = 48;

  everySecond: Observable<number> = timer(0, 5000);
  private subscription: Subscription;

  private map: any;
  private mapDefaultIcon: any;
  private mapGatewayMarker: any;

  commandsDisplayColumns: string[] = ['id', 'created', 'init', 'command', 'status', 'user', 'updated'];
  commandsDataSource = new MatTableDataSource([]);

  discoveredDevicesDisplayColumns: string[] = ['timestamp', 'rssi', 'packet_count', 'primary_phy', 'secondary_phy', 'protocol_id', 'tag_id', 'customer_id', 'alarm_cap_sensor', 'alarm_acc_triggered', 'alarm_battery_low', 'temperature', 'humidity', 'pipe_serial_number', 'pipe_length'];
  discoveredDevicesDataSource = new MatTableDataSource([]);

  @ViewChild('TableCommandsSort', { static: true }) sortCommands: MatSort;
  @ViewChild('TableCommandsPaginator', { static: true }) paginatorCommands: MatPaginator;

  @ViewChild('TableDiscoveredDevicesSort', { static: true }) sortDiscoveredDevices: MatSort;
  @ViewChild('TableDiscoveredDevicesPaginator', { static: true }) paginatorDiscoveredDevices: MatPaginator;

  // Command 
  limitCommandsToFetch = 100;
  sendCommandInProcess = false;
  sendCommandInit = false;
  sendCommandData = '';

  constructor(private route: ActivatedRoute, private session: SessionService, private dialog: MatDialog) { }

  private initMap(map_id: string): void {
    this.map = L.map(map_id, {
      tap: false,
      center: [63.44311673717589, 10.429234349394838],
      zoom: 15
    });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(this.map);

    this.mapDefaultIcon = new L.Icon({
      iconSize: [25, 41],
      iconAnchor: [10, 41],
      popupAnchor: [2, -40],
      iconUrl: 'leaflet/marker-icon.png',
      shadowUrl: 'leaflet/marker-shadow.png'
    })
  }

  private generatePopup(gateway: GatewayDTO) {
    return `<b>aws-name:</b> ${gateway.awsThingName}
    <br>
    <b>friendly-name:</b> ${gateway.friendlyName}`;
  }

  private mapRemoveGatewayMarker() {
    if (this.mapGatewayMarker != null) {
      this.map.removeLayer(this.mapGatewayMarker);
    }
    this.mapGatewayMarker = null;
  }

  private mapUpdateGateway(gateway: GatewayDTO): void {

    this.mapRemoveGatewayMarker();

    if (gateway.positionLat != null && gateway.positionLng != null) {
      this.mapGatewayMarker = L.marker([gateway.positionLat, gateway.positionLng], { icon: this.mapDefaultIcon }).addTo(this.map)
        .bindPopup(this.generatePopup(gateway));
      this.map.setView(this.mapGatewayMarker.getLatLng(), 16);
    }
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.commandsDataSource.paginator = this.paginatorCommands;
    this.commandsDataSource.sort = this.sortCommands;

    this.discoveredDevicesDataSource.paginator = this.paginatorDiscoveredDevices;
    this.discoveredDevicesDataSource.sort = this.sortDiscoveredDevices;

    this.initMap('gateway-details-map');

    this.getGatewayInformation();

    this.subscription = this.everySecond.subscribe((seconds) => {
      this.getGatewayInformation();
    })
  }

  checkIfOnline(timestamp: any): boolean {
    if (timestamp == null) return false;

    if (moment.utc().diff(moment.utc(timestamp).add(moment.duration(1, 'hour'))) < 0) {
      return true;
    } else {
      return false;
    }
  }

  getGatewayCommands(): void {
    // Getting gateway commands. 
    this.session.dataProvider.getGatewayCommands(this.gatewayId, this.limitCommandsToFetch).subscribe(commands => {
      this.commandsDataSource.data = commands;
    })
  }

  getGatewayInformation(): void {
    this.gatewayId = Number(this.route.snapshot.paramMap.get('id'));
    console.log("Selected gateway id: " + this.gatewayId);

    // Getting gateway information.
    this.session.dataProvider.getGateway(this.gatewayId).subscribe(gateway => {
      this.gateway = gateway;
      // Updating position on map if not in position update mode.
      if (!this.isPositionUpdate) {
        this.mapUpdateGateway(gateway);
      }
    });

    this.getGatewayCommands();

    // Getting gateway telemetry data. 
    this.session.dataProvider.getGatewayTelemetrySmartTag(this.gatewayId, this.gatewayDiscovereLastHours).subscribe(smartTags => {
      this.discoveredDevicesDataSource.data = smartTags;
    });
  }

  onCommandSelect(command: GatewayCommandDTO) {
    this.openDialogDescription(command.command);
  }

  private openDialogDescription(command: string) {
    var commandUpperCase = command ? command.toUpperCase() : "Empty Command";
    const dialogRef = this.dialog.open(DialogInformationComponent, { data: { title: 'Command Data', content: commandUpperCase } });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  sendCommand() {
    this.sendCommandInProcess = true;
    this.session.dataProvider.setGatewayCommand(this.gatewayId, this.sendCommandData, this.sendCommandInit).subscribe(
      (result) => {
        this.sendCommandInProcess = false;
        this.getGatewayCommands();
      },
      (error) => {
        this.sendCommandInProcess = false;
        console.log("Send command error: " + JSON.stringify(error));
      });
  }

  // Updating gateway location. 

  isPositionUpdate = false;
  previousPosition = null;
  isUpdadingPosition = false;

  onPositionUpdate() {
    if (this.mapGatewayMarker == null) {
      let mapCenter = this.map.getCenter();
      this.mapUpdateGateway({ positionLat: mapCenter.lat, positionLng: mapCenter.lng });
    } else {
      this.previousPosition = this.mapGatewayMarker.getLatLng();
    }

    this.isPositionUpdate = true;
    this.mapGatewayMarker.dragging.enable();
  }

  onPositionSave() {
    this.mapGatewayMarker.dragging.disable();
    let newPosition = this.mapGatewayMarker.getLatLng();
    console.log(`New position - ${newPosition}`);
    this.isUpdadingPosition = true;

    this.session.dataProvider.updateGatewayLocation(this.gateway.id, { latitude: newPosition.lat, longitude: newPosition.lng }).subscribe(
      (result) => {
        this.isPositionUpdate = false;
        this.isUpdadingPosition = false;
      },
      (error) => {
        this.isPositionUpdate = false;
        this.isUpdadingPosition = true;
        this.cancelPositionUpdate();
      });
  }

  onPositionCancel() {
    this.isPositionUpdate = false;
    this.mapGatewayMarker.dragging.disable();
    // Canceling position update.
    this.cancelPositionUpdate();
  }

  onCmdTemplateStart() {
    this.sendCommandData = '1001010000001800010400000000020409D1010103022C01050284000402D0070000';
  }

  onCmdTemplateStop() {
    this.sendCommandData = '10100000000000000000';
  }

  private cancelPositionUpdate() {
    if (this.previousPosition != null) {
      this.mapGatewayMarker.setLatLng(this.previousPosition);
      this.previousPosition = null;
    } else {
      this.mapRemoveGatewayMarker();
    }
  }
}
