import { Injectable } from '@angular/core';
import { AnalyticService, ApiService, AuthService, CompanyDTO, CompanyStatisticDTO, DeviceDTO, DeviceType, GatewayCommandDTO, GatewayDTO, GatewayLocationUpdateDTO, SmartTagTelemetryDataDTO, StatusLocationDTO, UserAccessKeyDTO, UserDTO } from 'app/lib/locator-api';

import { Observable } from 'rxjs';
import { forkJoin } from 'rxjs';  // RxJS 6 syntax
import { delay, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataProviderService {

  public user: UserDTO;
  public company: CompanyDTO;

  constructor(private apiService: ApiService, private analyticService: AnalyticService) { }

  private setToken(jwtToken: String) {
    let apiKey: { [key: string]: string } = {};
    apiKey["Authorization"] = "Bearer " + jwtToken;

    this.apiService.configuration.apiKeys = apiKey;
    this.analyticService.configuration.apiKeys = apiKey;
  }

  /**
   * Initializing data provider. Used in the resolver during application startup. 
   * 
   * @param jwtToken 
   * @returns 
   */
  init(jwtToken: String): Observable<any> {
    this.setToken(jwtToken);

    return new Observable((observer) => {
      forkJoin([
        this.apiService.apiUserGet(),
        this.apiService.apiCompaniesGet()
      ]).subscribe(
        (res) => {
          console.log("DataProvider login done.");
          this.user = res[0];
          this.company = res[1][0];
          observer.next(null);
          observer.complete();
        },
        (err) => {
          observer.error(err);
        });
    });
  }

  getGateways(): Observable<Array<GatewayDTO>> {
    return this.apiService.apiGatewaysCompanyIdGet(this.company.id);
  }

  getGateway(gatewayId: number): Observable<GatewayDTO> {
    return this.apiService.apiGatewayGatewayIdGet(gatewayId);
  }

  getGatewayLocation(gatewayId: number): Observable<StatusLocationDTO> {
    return this.apiService.apiGatewayGatewayIdLocationGet(gatewayId);
  }

  getGatewayLocationForPeriod(gatewayId: number, dateTimeFrom: string, dateTimeTo: string): Observable<Array<StatusLocationDTO>> {
    return this.apiService.apiGatewayGatewayIdLocationPeriodDateTimeFromDateTimeToGet(gatewayId, dateTimeFrom, dateTimeTo);
  }

  getDevices(): Observable<Array<DeviceDTO>> {
    return this.apiService.apiDevicesCompanyIdDeviceTypeGet(this.company.id, DeviceType.SMARTTAG);
  }

  getDevicesGeneral(): Observable<Array<DeviceDTO>> {
    return this.apiService.apiDevicesCompanyIdDeviceTypeGet(this.company.id, DeviceType.All);
  }

  getDevice(deviceId: number): Observable<DeviceDTO> {
    return this.apiService.apiDeviceCompanyIdDeviceIdDeviceTypeGet(this.company.id, deviceId, DeviceType.SMARTTAG);
  }

  getDeviceGeneral(deviceId: number): Observable<DeviceDTO> {
    return this.apiService.apiDeviceCompanyIdDeviceIdDeviceTypeGet(this.company.id, deviceId, DeviceType.All);
  }

  getDeviceTelementry(deviceId: number, lastHours: number): Observable<Array<SmartTagTelemetryDataDTO>> {
    return this.apiService.apiTdDeviceCompanyIdDeviceIdLastHoursGet(this.company.id, deviceId, lastHours);
  }

  getGatewayTelemetrySmartTag(gatewayId: number, lastHours: number): Observable<Array<SmartTagTelemetryDataDTO>> {
    return this.apiService.apiTdGatewayCompanyIdGatewayIdDeviceTypeLastHoursGet(this.company.id, gatewayId, DeviceType.SMARTTAG, lastHours);
  }

  getGatewayCommands(gatewayId: number, limit: number): Observable<Array<GatewayCommandDTO>> {
    return this.apiService.apiGatewayCommandsGatewayIdLimitGet(gatewayId, limit);
  }

  setGatewayCommand(gatewayId: number, command: string, init: boolean): Observable<number> {
    return this.apiService.apiGatewayCommandSendPost({
      gatewayId: gatewayId,
      command: command,
      init: init
    });
  }

  updateGatewayLocation(gatewayId: number, locationUpdate: GatewayLocationUpdateDTO) {
    return this.apiService.apiGatewayGatewayIdUpdateLocationPost(gatewayId, locationUpdate);
  }

  getCompanyStatistic(thresholdGatewayOnlineHours: number, thresholdDeviceOnlineHours: number, limitHistoryDays: number): Observable<CompanyStatisticDTO> {
    return this.analyticService.apiAnalyticCompanyCompanyIdThresholdGatewayOnlineHoursThresholdDeviceOnlineHoursLimitHistoryDaysGet(this.company.id, thresholdGatewayOnlineHours, thresholdDeviceOnlineHours, limitHistoryDays)
  }

  getAccessKeys(): Observable<Array<UserAccessKeyDTO>> {
    return this.apiService.apiUserAccessKeyGet();
  }

  generateAccessKey(): Observable<UserAccessKeyDTO> {
    return this.apiService.apiUserAccessKeyPost();
  }
}
