import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emptyTextFormatter'
})
export class EmptyTextFormatterPipe implements PipeTransform {

  transform(value: any, defaultText: string): any {
    if (value == null) {
      return defaultText
    }
    return value;
  }

}
