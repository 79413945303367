import { Injectable } from '@angular/core';
import { LocalStorageService } from 'app/services/storage/local-storage.service';
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class JWTTokenService {
  jwtToken: string;
  decodedToken: any;

  constructor(private localStorageService: LocalStorageService) {
    this.jwtToken = localStorageService.get("jwt_token");
  }

  removeToken() {
    this.jwtToken = null;
    this.localStorageService.remove("jwt_token");
  }

  getToken(): string {
    return this.jwtToken;
  }

  setToken(token: string) {
    if (token) {
      this.jwtToken = token;
      this.localStorageService.set("jwt_token", token);
    }
  }

  decodeToken() {
    if (this.jwtToken) {
      this.decodedToken = jwt_decode(this.jwtToken);
      console.log(JSON.stringify(this.decodedToken));
    }
  }

  getUser() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken.displayname : null;
  }

  getEmailId() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken.email : null;
  }

  getExpiryTime() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken.exp : null;
  }

  isTokenExpired(): boolean {
    const expiryTime: number = this.getExpiryTime();
    if (expiryTime) {
      return ((1000 * expiryTime) - (new Date()).getTime()) < 5000;
    } else {
      return true;
    }
  }
}
