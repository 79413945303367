import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import * as L from 'leaflet';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatFormField } from '@angular/material/form-field';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SessionService } from 'app/services/session/session.service';
import { GatewayDTO } from 'app/lib/locator-api';


@Component({
  selector: 'app-gateway',
  templateUrl: './gateway.component.html',
  styleUrls: ['./gateway.component.scss']
})
export class GatewayComponent implements AfterViewInit {

  displayedColumns: string[] = ['id', 'lastActive', 'awsThingName', 'friendlyName', 'imei'];
  gatewaySource = new MatTableDataSource([]);
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  private map;
  private mapDefaultIcon;
  private mapMarkers: any[] = [];

  private initMap(): void {
    this.map = L.map('gateway-map', {
      tap: false,
      center: [63.44311673717589, 10.429234349394838],
      zoom: 14
    });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(this.map);

    this.mapDefaultIcon = new L.Icon({
      iconSize: [25, 41],
      iconAnchor: [10, 41],
      popupAnchor: [2, -40],
      iconUrl: 'leaflet/marker-icon.png',
      shadowUrl: 'leaflet/marker-shadow.png'
    })
  }

  private generatePopup(gateway: GatewayDTO){
    return `<a href="#/gateway/details/${gateway.id}">Show Details</a>
    <br>
    <b>aws-name:</b> ${gateway.awsThingName}
    <br>
    <b>friendly-name:</b> ${gateway.friendlyName}`;
  }

  private updateMap(gateways: GatewayDTO[]) {

    // Removing markers.
    this.mapMarkers.forEach(marker => {
      this.map.removeLayer(marker);
    });
    var firstLoad = false;
    if (this.mapMarkers.length == 0) {
      firstLoad = true;
    }
    this.mapMarkers = [];

    gateways.forEach(gateway => {
      if (gateway.positionLat != null && gateway.positionLng != null) {
        var marker = L.marker([gateway.positionLat, gateway.positionLng], { icon: this.mapDefaultIcon }).addTo(this.map).bindPopup(this.generatePopup(gateway));
        this.mapMarkers.push(marker);
      }
    });

    if(firstLoad){
      var group = new L.featureGroup(this.mapMarkers);
      this.map.fitBounds(group.getBounds());
    }
  }

  constructor(private router: Router, private session: SessionService) { }

  updateData(gateways: GatewayDTO[]) {
    this.gatewaySource.data = gateways;
    // Update map inormation. 
    this.updateMap(gateways);

  }

  ngOnInit(): void {
    this.session.dataProvider.getGateways().subscribe(
      result => {
        this.updateData(result)
      }
    );
  }

  ngAfterViewInit(): void {
    this.gatewaySource.paginator = this.paginator;
    this.gatewaySource.sort = this.sort;
    this.initMap()
  }

  trackTask(index: number, item: GatewayDTO): string {
    return `${item.id}`;
  }

  applySearch(filterValue: string) {
    this.gatewaySource.filter = filterValue.trim().toLowerCase();
  }

  onGatewaySelect(item: GatewayDTO): void {
    this.router.navigate(['gateway/details/', item.id]);
  }
}