import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';

import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { Router } from '@angular/router';
import { SessionService } from 'app/services/session/session.service';

@Component({
  selector: 'app-device-general',
  templateUrl: './device-general.component.html',
  styleUrls: ['./device-general.component.scss']
})

export class DeviceGeneralComponent implements OnInit {

  displayedColumns: string[] = ['id', 'uid', 'type', 'friendly_name'];
  deviceSource = new MatTableDataSource([]);

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private router: Router, private session: SessionService) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.deviceSource.paginator = this.paginator;
    this.deviceSource.sort = this.sort;

    this.deviceSource.sortingDataAccessor = (item, property) => {

      switch (property) {
        case 'uid': return item.uid;
        case 'type': return item.type;
        case 'friendly_name': return item.friendly_name;
        default: return item[property];
      }
    }

    this.session.dataProvider.getDevicesGeneral().subscribe(
      devices => {
        // console.log(`${JSON.stringify(devices)}`);
        this.deviceSource.data = devices;
      }
    );
  }

  trackTask(index: number, item): string {
    return `${item.id}`;
  }

  applySearch(filterValue: string) {
    this.deviceSource.filter = filterValue.trim().toLowerCase();
  }

  onDeviceSelect(item): void {
    this.router.navigate(['device-general/details-general/', item.id]);
  }

}
