import { Injectable } from '@angular/core';
import { JWTTokenService } from '../jwt-token/jwttoken.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '../session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    private session: SessionService,
    private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

    console.log("Auth Guard");

    if (this.session.isValid()) {
      this.router.navigate(['login']);
      return false;
    } else {
      return true;
    }

  }
}
