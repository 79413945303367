import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';


import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';

import {
  AgmCoreModule
} from '@agm/core';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';

import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { GatewayDetailsComponent } from './pages/gateway-details/gateway-details.component';
import { SettingsComponent } from './pages/settings/settings.component'

import { ApiModule, BASE_PATH } from './lib/locator-api';
import { GatewayComponent } from './pages/gateway/gateway.component';
import { LoginComponent } from './pages/login/login.component';
import { DeviceComponent } from './pages/device/device.component';
import { DeviceGeneralComponent } from './pages/device-general/device-general.component';
import { DeviceDetailsComponent } from './pages/device-details/device-details.component';
import { DeviceDetailsGeneralComponent } from './pages/device-details-general/device-details-general.component';
import { TimestampFormatterPipe } from './pipes/Timestamp/timestamp-formatter.pipe';
import { EmptyTextFormatterPipe } from './pipes/text/empty-text-formatter.pipe';
import { DialogInformationComponent } from './dialogs/dialog-information/dialog-information.component';

import { HexadecimalDirective } from './directives/hexadecimal/hexadecimal.directive';  // Checking that data of the input field is a HEX data. 

@NgModule({
  imports: [
    MatProgressBarModule,
    MatIconModule,
    MatRadioModule, 
    MatDialogModule,
    MatTabsModule,
    MatPaginatorModule,
    MatButtonModule,
    MatSortModule,
    MatTableModule,
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    ApiModule,
    AgmCoreModule.forRoot({
      apiKey: 'YOUR_GOOGLE_MAPS_API_KEY'
    })
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    GatewayComponent,
    DeviceComponent,
    DeviceGeneralComponent,
    DeviceDetailsGeneralComponent,
    GatewayDetailsComponent,
    DeviceDetailsComponent,
    SettingsComponent,
    TimestampFormatterPipe,
    EmptyTextFormatterPipe,
    DialogInformationComponent,
    HexadecimalDirective,
  ],
  providers: [TimestampFormatterPipe, { provide: BASE_PATH, useValue: 'https://api.norbitiot.com' }],
  //providers: [TimestampFormatterPipe, { provide: BASE_PATH, useValue: 'https://localhost:5001' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
