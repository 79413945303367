import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { delay, take, } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { SessionService } from 'app/services/session/session.service';

interface IReturn {
  email: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApiStartupResolver implements Resolve<any>{

  constructor(private session: SessionService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const withError = route.params['with-error'] === 'true';
    console.log("Calling observer");

    return this.session.init().pipe(catchError(error => {
      console.log("error: " + error);
      return of(null);
    }));
  }

}
