import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import * as Chartist from 'chartist';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
// import { DeviceInformation, DEVICE_DATA } from 'app/device/device.component';
import * as L from 'leaflet';
import { DeviceDTO, SmartTagTelemetryDataDTO } from 'app/lib/locator-api';
import { ActivatedRoute } from '@angular/router';
import { SessionService } from 'app/services/session/session.service';
import { MatDialog } from '@angular/material/dialog';
import { TimestampFormatterPipe } from 'app/pipes/Timestamp/timestamp-formatter.pipe';

import * as moment from 'moment';
import { SmartTagDeviceDTO } from 'app/lib/locator-api/model/smartTagDeviceDTO';

@Component({
  selector: 'app-device-details',
  templateUrl: './device-details.component.html',
  styleUrls: ['./device-details.component.scss']
})
export class DeviceDetailsComponent implements OnInit {
  private map;
  private mapDefaultIcon;
  private mapMarker;

  deviceId: number;

  gettingTelemeteryDataInProgress = true;
  limitHistoryHours: number = 24 * 7; // 7 days

  device: SmartTagDeviceDTO = {};

  displayedColumns: string[] = ['timestamp', 'gateway_id', 'rssi', 'packet_count', 'primary_phy', 'secondary_phy', 'protocol_id', 'tag_id', 'customer_id', 'alarm_cap_sensor', 'alarm_acc_triggered', 'alarm_battery_low', 'temperature', 'humidity', 'pipe_serial_number', 'pipe_supplier', 'customer_code', 'customer_site_code', 'project_code', 'pipe_length'];
  deviceSource = new MatTableDataSource([]);

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private route: ActivatedRoute, private session: SessionService, private dialog: MatDialog, private timestampFormatterPipe: TimestampFormatterPipe) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {

    this.deviceSource.paginator = this.paginator;
    this.deviceSource.sort = this.sort;

    this.initMap('device-details-map');

    this.getDeviceInformation();
  }

  private initMap(map_id: string): void {

    this.map = L.map(map_id, {
      tap: false,
      center: [63.44311673717589, 10.429234349394838],
      zoom: 15
    });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(this.map);

    this.mapDefaultIcon = new L.Icon({
      iconSize: [25, 41],
      iconAnchor: [10, 41],
      popupAnchor: [2, -40],
      iconUrl: 'leaflet/marker-icon.png',
      shadowUrl: 'leaflet/marker-shadow.png'
    })
  }

  private generatePopup(timestamp: Date) {

    return `<b>Timestamp:</b> ${this.timestampFormatterPipe.transform(timestamp.toString(), 'YYYY-MM-D HH:mm:ss')}`;
  }

  private mapUpdateDevice(device: SmartTagDeviceDTO): void {

    if (this.mapMarker != null) {
      this.map.removeLayer(this.mapMarker);
    }
    this.mapMarker = null;

    if (device.status?.positionLat != null && device.status?.positionLng != null) {
      this.mapMarker = L.marker([device.status?.positionLat, device.status?.positionLng], { icon: this.mapDefaultIcon }).addTo(this.map)
        .bindPopup(this.generatePopup(device.status?.timestamp));
      this.map.setView(this.mapMarker.getLatLng(), 16);
    }
  }

  trackTask(index: number, item: SmartTagDeviceDTO): string {
    return `${item.id}`;
  }

  getDeviceInformation(): void {
    this.deviceId = Number(this.route.snapshot.paramMap.get('id'));

    // Loading device information. 
    this.session.dataProvider.getDevice(this.deviceId).subscribe((result) => {
      this.uiUpdateDeviceInformation(result);
    });

    this.gettingTelemeteryDataInProgress = true;

    this.session.dataProvider.getDeviceTelementry(this.deviceId, this.limitHistoryHours).subscribe((result) => {
      this.deviceSource.data = result;
      this.uiUpdateGraphs(result);
      this.gettingTelemeteryDataInProgress = false;
    }, (error) => {
      this.gettingTelemeteryDataInProgress = false;
    });
  }

  private uiUpdateDeviceInformation(device: SmartTagDeviceDTO) {
    this.device = device;
    this.mapUpdateDevice(device);
  }

  selectedParameterForChart = 'temperature'

  private groupday(value: SmartTagTelemetryDataDTO, index, array) {
    var byday = { id: null, object: null };
    let dateValue = new Date(value['timestamp'])

    var periodTrunc = 1000 * 60 * 60 * 24;

    var d = Math.floor(dateValue.getTime() / periodTrunc);
    byday['id'] = d * periodTrunc;
    byday['object'] = value;
    return byday
  }

  private uiUpdateGraphs(data: SmartTagTelemetryDataDTO[]) {

    var group_day = data.map(this.groupday);

    var aggregated: { [date: number]: any; } = {}

    for (var record of group_day) {
      if (aggregated[record.id] == null) {
        aggregated[record.id] = record.object[this.selectedParameterForChart];
      }
    }

    var labels = []
    var values = []

    for (let key in aggregated) {
      let convertedTime = +key / 1000;
      labels.push(moment.unix(convertedTime).local().format('DD.MM'));
      values.push(aggregated[key]);
    }


    this.setGraph(labels, values);
  }

  private setGraph(labels: string[], series: number[]) {
    const dataDailySalesChart: any = {
      labels: labels,
      series: [series]
    };

    let heightMax = Math.max.apply(Math, series);
    let heightMin = Math.min.apply(Math, series);
    const optionsDailySalesChart: any = {
      lineSmooth: Chartist.Interpolation.cardinal({
        tension: 0
      }),
      low: heightMin >= 0 ? 0 : heightMin + 0.1 * heightMin,
      high: heightMax <= 0 ? 0 : heightMax + 0.1 * heightMax, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
      chartPadding: { top: 0, right: 0, bottom: 0, left: 0 },
    }

    var dailySalesChart = new Chartist.Line('#deviceParameterChart', dataDailySalesChart, optionsDailySalesChart);
  }

  selectedParameterChange(event) {
    this.uiUpdateGraphs(this.deviceSource.data);
  }

}