import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';

import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { Router } from '@angular/router';
import { SessionService } from 'app/services/session/session.service';
import { SmartTagDeviceDTO } from 'app/lib/locator-api/model/smartTagDeviceDTO';

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss']
})

export class DeviceComponent implements OnInit {

  displayedColumns: string[] = ['timestamp', 'tag_id', 'friendly_name','protocol_id','customer_id', 'alarm_cap_sensor', 'alarm_acc_triggered', 'alarm_battery_low', 'temperature', 'humidity', 'pipe_serial_number', 'pipe_length'];
  deviceSource = new MatTableDataSource([]);

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private router: Router, private session: SessionService) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.deviceSource.paginator = this.paginator;
    this.deviceSource.sort = this.sort;

    this.deviceSource.sortingDataAccessor = (item: SmartTagDeviceDTO, property) => {

      switch (property) {
        case 'timestamp': return item.status.timestamp;
        case 'protocol_id': return item.status.protocolId;
        case 'tag_id': return item.status.tagId;
        case 'customer_id': return item.status.customerId;
        case 'alarm_cap_sensor': return item.status.alarmCapSensor;
        case 'alarm_acc_triggered': return item.status.alarmAccTriggered;
        case 'alarm_battery_low': return item.status.alarmBatteryLow;
        case 'temperature': return item.status.temperature;
        case 'humidity': return item.status.humidity;
        case 'pipe_serial_number': return item.status.pipeSerialNumber;
        case 'pipe_length': return item.status.pipeLength;
        default: return item[property];
      }
    }

    this.session.dataProvider.getDevices().subscribe(
      devices => {
        this.deviceSource.data = devices;
      }
    );
  }

  trackTask(index: number, item: SmartTagDeviceDTO): string {
    return `${item.id}`;
  }

  applySearch(filterValue: string) {
    this.deviceSource.filter = filterValue.trim().toLowerCase();
  }

  onDeviceSelect(item: SmartTagDeviceDTO): void {
    this.router.navigate(['device/details/', item.id]);
  }

}
