import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DataProviderService } from './data/data-provider.service';
import { JWTTokenService } from './jwt-token/jwttoken.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private user: any;

  constructor(private jwtTokenService: JWTTokenService,
    private router: Router,
    public dataProvider: DataProviderService) { }


  isValid() {
    return this.jwtTokenService.isTokenExpired()
  }

  logout() {
    this.jwtTokenService.removeToken();
    this.router.navigate(['login']);
  }

  init(): Observable<any> {
    return this.dataProvider.init(this.jwtTokenService.getToken());
  }
}
