import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { NgZone } from '@angular/core';

import { JWTTokenService } from 'app/services/session/jwt-token/jwttoken.service';
import { AuthService } from 'app/lib/locator-api';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  error_reason: string;
  username: string = "";
  password: string = "";
  showSpinner: boolean = false;

  constructor(private ngZone: NgZone, private router: Router, private route: ActivatedRoute, private jwtService: JWTTokenService, private authService: AuthService) { }

  ngOnInit(): void {
  }

  login(): void {
    this.showSpinner = true;
    this.error_reason = null;

    this.authService.apiAuthLoginPost({
      username: this.username,
      password: this.password
    }).subscribe(result => {
      // Handle result
      console.log(result);
      this.jwtService.setToken(result);
      this.router.navigate(['dashboard']);
    },
      error => {
        console.log(JSON.stringify(error));
        this.showSpinner = false;
        this.error_reason = "Wrong Username/Password";
      },
      () => {
        // 'onCompleted' callback.
        // No errors, route to new page here
      })


  }
}
