import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogInformationData {
  title: string;
  content: string; 
}

@Component({
  selector: 'app-dialog-information',
  templateUrl: './dialog-information.component.html',
  styleUrls: ['./dialog-information.component.css']
})
export class DialogInformationComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogInformationData) { }

  ngOnInit(): void {
  }

}
