import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogInformationComponent } from 'app/dialogs/dialog-information/dialog-information.component';
import { UserAccessKeyDTO } from 'app/lib/locator-api';
import { SessionService } from 'app/services/session/session.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  accessKeysList: UserAccessKeyDTO[] = []

  constructor(private session: SessionService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.updateData();
  }


  generateAccessKey() {
    this.session.dataProvider.generateAccessKey().subscribe((result) => {
      this.openDialog(result);
      this.updateData();
    });
  }

  updateData() {
    this.session.dataProvider.getAccessKeys().subscribe((result) => {
      result = result.sort((b,a) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
      this.accessKeysList = result
    });
  }

  private openDialog(accessKey: UserAccessKeyDTO) {
    
    const dialogRef = this.dialog.open(DialogInformationComponent, { data: { title: `Access Key: ${accessKey.accessKey}`, content: `Secret: ${accessKey.secret}` } });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
