import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import * as Chartist from 'chartist';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
// import { DeviceInformation, DEVICE_DATA } from 'app/device/device.component';
import * as L from 'leaflet';
import { DeviceDTO, SmartTagTelemetryDataDTO } from 'app/lib/locator-api';
import { ActivatedRoute } from '@angular/router';
import { SessionService } from 'app/services/session/session.service';
import { MatDialog } from '@angular/material/dialog';
import { TimestampFormatterPipe } from 'app/pipes/Timestamp/timestamp-formatter.pipe';

import * as moment from 'moment';
import { SmartTagDeviceDTO } from 'app/lib/locator-api/model/smartTagDeviceDTO';

@Component({
  selector: 'app-device-details-general',
  templateUrl: './device-details-general.component.html',
  styleUrls: ['./device-details-general.component.scss']
})
export class DeviceDetailsGeneralComponent implements OnInit {

  deviceId: number;

  gettingTelemeteryDataInProgress = true;
  limitHistoryHours: number = 24 * 7; // 7 days

  device: SmartTagDeviceDTO = {};

  DISPLAY_COLUMNS_SMART_TAG: string[] = ['timestamp', 'gateway_id', 'rssi', 'packet_count', 'primary_phy', 'secondary_phy', 'protocol_id', 'tag_id', 'customer_id', 'alarm_cap_sensor', 'alarm_acc_triggered', 'alarm_battery_low', 'temperature', 'humidity', 'pipe_serial_number', 'pipe_supplier', 'customer_code', 'customer_site_code', 'project_code', 'pipe_length'];
  DISPLAY_COLUMNS_GENERAL: string[] = ['timestamp', 'gateway_id', 'rssi', 'packet_count', 'primary_phy', 'secondary_phy', 'data_json'];
  displayedColumns: string[] = ['timestamp', 'gateway_id', 'rssi', 'packet_count', 'primary_phy', 'secondary_phy', 'protocol_id', 'tag_id', 'customer_id', 'alarm_cap_sensor', 'alarm_acc_triggered', 'alarm_battery_low', 'temperature', 'humidity', 'pipe_serial_number', 'pipe_supplier', 'customer_code', 'customer_site_code', 'project_code', 'pipe_length'];
  deviceSource = new MatTableDataSource([]);

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private route: ActivatedRoute, private session: SessionService, private dialog: MatDialog, private timestampFormatterPipe: TimestampFormatterPipe) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {

    this.deviceSource.paginator = this.paginator;
    this.deviceSource.sort = this.sort;



    this.getDeviceInformation();
  }


  trackTask(index: number, item: SmartTagDeviceDTO): string {
    return `${item.id}`;
  }

  getDeviceInformation(): void {
    this.deviceId = Number(this.route.snapshot.paramMap.get('id'));

    // Loading device information. 
    this.session.dataProvider.getDeviceGeneral(this.deviceId).subscribe((result) => {
      // console.log(`Device information: ${JSON.stringify(result)}`);
      this.uiUpdateDeviceInformation(result);
    });
  }

  private uiUpdateDeviceInformation(device: SmartTagDeviceDTO) {
    this.device = device;

    if (device.type == 'smart_tag') {
      this.displayedColumns = this.DISPLAY_COLUMNS_SMART_TAG;
    } else {
      this.displayedColumns = this.DISPLAY_COLUMNS_GENERAL;
    }

    this.gettingTelemeteryDataInProgress = true;

    this.session.dataProvider.getDeviceTelementry(this.deviceId, this.limitHistoryHours).subscribe((result) => {

      this.deviceSource.data = result.map(x => {
        if(x['dataJson'] !== null && typeof x['dataJson'] !== 'undefined' ){
          x['dataJsonString'] = JSON.stringify(x['dataJson']);
        }
        return x;
      });
      this.gettingTelemeteryDataInProgress = false;
    }, (error) => {
      this.gettingTelemeteryDataInProgress = false;
    });
  }

}