import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timestampFormatter'
})
export class TimestampFormatterPipe implements PipeTransform {

  transform(value: string, dateFormat: string): unknown {
    if(value === null){
      return null;
    }
    return moment.utc(value).local().format(dateFormat);
  }

}
